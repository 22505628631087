import React, { useState, useEffect } from 'react';
import { useData } from '../../../DataProvider';
import { makeStyles, Icon, Tooltip, Typography, Grid, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import gql from 'graphql-tag';
import { Query, Subscription } from 'react-apollo';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

import Loading from '../../utils/Loading';
import LocationSelect from './LocationSelect';
import FavoriteSelect from './FavoriteSelect';

const log = false;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  lane: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  spacer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: theme.spacing(2),
    minHeight: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '48px',
    minHeight: '48px',
  },
  swapIcon: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    transition: '0.2s',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
    },
  },
  favIcon: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.error.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  favIconActive: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.light,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  laneInfo: {
    overflowY: 'auto',
    maxHeight: '480px',
    // minHeight: '110px',
    borderRadius: '8px',
    transition: 'visibility 1s',
  },
  laneData: {
    color: theme.palette.text.secondary,
  },
}));

////////// COMPONENT //////////
export default function LaneForm(props) {
  const ctx = useData();
  const cls = useStyles();

  const {
    defaultLane,
    lane,
    setLane,
    onLaneChange,
    getLaneByLocationIds,
    handleLanes,
    customerId,
    setLanePair,
    subscriptionSkip,
    setSubscriptionSkip,
    subscriptionData,
    type,
    doesLaneExist,
    setDoesLaneExist,
    newLaneId,
    customerConfig,
    isSubscriptionRendering,
    setIsSubscriptionRendering,
    pickupDeliveryChange,
    appointment,
  } = props;

  const [pickup, setPickup] = useState(lane && lane.pickup ? lane.pickup : null);
  const [delivery, setDelivery] = useState(lane && lane.delivery ? lane.delivery : null);
  const [expand, setExpand] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [laneLoading, setLaneLoading] = useState(false);
  const [priceAndDistanceConfigured, setPriceAndDistanceConfigured] = useState(null);

  useEffect(() => {
    if (appointment && defaultLane && defaultLane.id) {
      setPickup(defaultLane.pickup);
      setDelivery(defaultLane.delivery);
    } else if (defaultLane && defaultLane.id) {
      setPickup(defaultLane.pickup);
      setDelivery(defaultLane.delivery);
    }
  }, [defaultLane]);

  const DistanceEstimate = (laneIsLoading, configEnabled, miles = null) => {
    if ((!appointment && !configEnabled) || !miles || pickupDeliveryChange) return null;
    const doubleMove = type === 'round-trip' || type === 'loaner' ? true : false;
    let distanceText = 'Unable to estimate distance';
    if (miles) {
      distanceText = doubleMove ? miles + ' miles x2' : miles + ' miles';
    }
    return (
      <Typography component={'span'} style={{ margin: '20px', fontSize: '1.2rem' }}>
        <span className={cls.laneData}>ESTIMATED DISTANCE: </span>
        <span style={{ minWidth: '140px', display: 'inline-block' }}>
          {laneIsLoading ? <MiniLoading /> : distanceText}
        </span>
      </Typography>
    );
  };

  const PriceEstimate = (laneIsLoading, configEnabled, miles = null) => {
    if ((!appointment && !configEnabled) || !miles || pickupDeliveryChange) return null;
    const moveClass = type === 'round-trip' ? 'base' : 'stranded';
    const generatePriceText = rateData => {
      const rateRule = rateData && rateData.raterules && rateData.raterules.length > 0 ? rateData.raterules[0] : null;
      //If there is a missing raterule, we want to be alerted so we can enter a rate.
      if(!rateRule) return 'Missing price data. Please contact HopDrive support'
      const rate = rateRule.rate ? rateRule.rate : 0;
      const price = rateRule.type === 'flat' ? rate : rateRule.type === 'per' ? rate * miles : null;
      let priceText = 'Unable to estimate price';
      if (price) {
        priceText = type === 'round-trip' ? '$' + price.toFixed(2) + ' x2' : '$' + price.toFixed(2);
      }
      return priceText;
    };
    return (
      <Query
        query={GET_RATE_RULE}
        variables={{ customerId: customerId, distance: miles, class: moveClass }}
        onError={error => {
          console.error(error);
          Sentry.captureException(error);
        }}
      >
        {({ loading, data }) => {
          if (loading) return <MiniLoading />;
          if (data) {
            return (
              <Typography component={'span'} style={{ margin: '20px', fontSize: '1.2rem' }}>
                <span className={cls.laneData}>ESTIMATED PRICE: </span>
                <span style={{ minWidth: '140px', display: 'inline-block' }}>
                  {laneIsLoading ? <MiniLoading /> : generatePriceText(data)}
                </span>
              </Typography>
            );
          }
        }}
      </Query>
    );
  };

  const ExistingLaneEstimates = () => {
    const isLoading = laneLoading && pickup && delivery;
    const miles = lane && lane.distance_miles ? lane.distance_miles : null;
    return (
      <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          {DistanceEstimate(isLoading, (appointment ? appointment : customerConfig && customerConfig.distance_estimates ? customerConfig.distance_estimates : false), miles)}
      
          {PriceEstimate(isLoading, (appointment ? appointment : customerConfig && customerConfig.price_estimates ? customerConfig.price_estimates : false), miles)}

        </Grid>
      </Grid>
      <Grid container justifyContent='center' style={{marginBottom: '20px'}}>
        <Grid item xs={7}>
          <Alert severity="info" style={{width: 'fit-content'}}>
          Price estimates are based on vehicle weight classes 1 & 2. The price will increase for class 3 vehicles and above.
          </Alert>
        </Grid>
      </Grid>
      </>
    );
  };

  const NewLaneEstimates = () => {
    const laneData = subscriptionData && subscriptionData.length > 0 ? subscriptionData[0] : null;
    const isLoading = subscriptionLoading && pickup && delivery;
    const miles = laneData && laneData.distance_miles ? laneData.distance_miles : null;
    log && console.log('customerConfig', customerConfig, 'subscription data: ', subscriptionData);
    return (
      <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          {DistanceEstimate(isLoading, customerConfig.distance_estimates, miles)}

          {PriceEstimate(isLoading, customerConfig.price_estimates, miles)}
        </Grid>
      </Grid>
      <Grid container justifyContent='center' style={{marginBottom: '20px'}}>
        <Grid item xs={7}>
          <Alert severity="info" style={{width: 'fit-content'}}>
            Price estimates are based on vehicle weight classes 1 & 2. The price will increase for class 3 vehicles and above.
          </Alert>
        </Grid>
      </Grid>
      </>
    );
  };

  const MiniLoading = props => {
    return (
      <CircularProgress
        size='1.2rem'
        style={{
          top: props.top || 0,
          left: props.left || 0,
          bottom: props.bottom || 0,
          right: props.right || 0,
          marginLeft: '15px',
        }}
      />
    );
  };

  useEffect(() => {
    if (subscriptionLoading) {
      setTimeout(() => {
        setSubscriptionLoading(false);
      }, 15000);
    }
  }, [subscriptionLoading]);

  // useEffect(() => {
  //   log && console.log('LaneForm: Received updated default lane', lane);
  //   if (lane && lane.pickup) setPickup(lane.pickup);
  //   if (lane && lane.delivery) setDelivery(lane.delivery);
  // }, [lane]);

  //Determine whether customer is configured to see move price and distance estimates
  useEffect(() => {
    if (
      customerConfig &&
      (customerConfig.price_estimates || customerConfig.distance_estimates) &&
      (customerConfig.price_estimates === true || customerConfig.distance_estimates === true)
        ? true
        : false
    ) {
      setPriceAndDistanceConfigured(true);
    } else if (appointment) {
      setPriceAndDistanceConfigured(true)
    } else setPriceAndDistanceConfigured(false);
  }, [customerConfig]);

  //Set states for price and distance estimates based on whether lane already exists or is still being created
  useEffect(() => {
    if (
      lane &&
      lane !== undefined &&
      lane !== null &&
      lane.dealer_base_price !== null &&
      lane.dealer_base_price !== undefined &&
      lane.dealer_stranded_price !== null &&
      lane.dealer_stranded_price !== undefined &&
      lane.distance_miles !== null &&
      lane.distance_miles !== undefined
    ) {
      setLaneLoading(false);
    } else setLaneLoading(true);
  });

  useEffect(() => {
    log && console.log(subscriptionData, 'subscription data');
  }, [subscriptionData]);

  //set states for move price and distance estimates based on lane subscription status
  useEffect(() => {
    if (subscriptionData) {
      setDoesLaneExist(false);
      setIsSubscriptionRendering(true);
    }

    if (
      subscriptionData &&
      subscriptionData.length > 0 &&
      subscriptionData[0].dealer_base_price !== null &&
      subscriptionData[0].dealer_stranded_price !== null &&
      subscriptionData[0].distance_miles !== null
    ) {
      setSubscriptionLoading(false);
    } else setSubscriptionLoading(true);

    if (subscriptionData) {
      //Subscription will terminate when these values are no longer null
      if (
        subscriptionData[0].dealer_base_price === null ||
        subscriptionData[0].dealer_stranded_price === null ||
        subscriptionData[0].distance_miles === null
      ) {
        setSubscriptionSkip(false);
      } else {
        setSubscriptionSkip(true);
      }
    }
  }, [subscriptionData]);

  useEffect(() => {
    handleLane();
  }, [pickup, delivery]);

  //If a new lane is being built, initiate a subscription to listen for when it's complete
  useEffect(() => {
    if (newLaneId !== null) {
      setSubscriptionSkip(false);
    }
  }, [newLaneId]);

  //Check to see if the lane already exists to determine whether to render this or subscription data
  useEffect(() => {
    if (
      lane &&
      lane.dealer_base_price !== null &&
      lane.dealer_base_price !== undefined &&
      lane.dealer_stranded_price !== null &&
      lane.dealer_stranded_price !== undefined &&
      lane.distance_miles !== null &&
      lane.distance_miles !== undefined
    ) {
      setDoesLaneExist(true);
      setIsSubscriptionRendering(false);
    }
  }, [lane]);

  const handleLane = async () => {
    if (pickup && delivery) {
      if (pickup.id === delivery.id) {
        log && console.log('Duplicate locations');
        if (onLaneChange) onLaneChange({ pickup: null, delivery: null });
      } else {
        let lanePairRes = await handleLanes(customerId, pickup, delivery);
        const foundLane = await getLaneByLocationIds(pickup.id, delivery.id);
        log && console.log(`Setting new lane... (Full Lane):`, foundLane);
        if (!foundLane) {
          if (onLaneChange) {
            onLaneChange({ pickup: pickup, delivery: delivery });
            setLane(lanePairRes[0]);
            setLanePair([lanePairRes[0], lanePairRes[1]]);
          }
        } else {
          if (onLaneChange) onLaneChange(foundLane);
          setLane(foundLane);
          setLanePair([lanePairRes[0], lanePairRes[1]]);
        }
      }
    } else if (pickup) {
      if (onLaneChange) onLaneChange({ pickup: pickup, delivery: delivery });
    } else if (delivery) {
      if (onLaneChange) onLaneChange({ pickup: pickup, delivery: delivery });
    } else {
      if (onLaneChange) onLaneChange({ pickup: null, delivery: null });
    }
  };

  const handlePickup = pickup => {
    setPickup(pickup);
  };

  const handleDelivery = delivery => {
    setDelivery(delivery);
  };

  const handleFavoriteSelect = newLane => {
    // setLane(null)
    if (priceAndDistanceConfigured && subscriptionSkip === false) {
      return;
    } else {
      setPickup(newLane.pickup);
      setDelivery(newLane.delivery);
      setLane(newLane);
    }
  };

  const handleSwapLocations = () => {
    if (priceAndDistanceConfigured && subscriptionSkip === false) {
      return;
    } else {
      setPickup(delivery);
      setDelivery(pickup);
      log && console.log(`Locations have been swapped!`);
    }
  };

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleLaneFavorite = async favoriteLanes => {
    if (
      subscriptionData &&
      subscriptionData[0].dealer_base_price === null &&
      subscriptionData[0].dealer_stranded_price === null &&
      subscriptionData[0].distance_miles === null
    ) {
      return;
    }

    if (lane.id) {
      try {
        const res = await ctx.apolloClient.mutate({
          mutation: UPDATE_LANE_FAVORITE,
          variables: { id: lane.id, fav: !laneIsFavorited(lane, favoriteLanes) },
        });
        if (res.data && res.data.update_lanes) {
          onLaneChange({ ...lane, favorite: !lane.favorite });
          log && console.log(`>> UPDATED Favorite Lane to ${!lane.favorite}:`, res.data.update_lanes);
        }
      } catch (err) {
        log && console.log(`Failed to update lane:`, err);
        toast.error(`Failed to update lane`);
      }
    } else log && console.log(`Error: Lane is not valid and must be created before setting favorite!`);
  };

  const laneIsFavorited = (lane, lanes) => {
    lanes
      .filter(o => o.favorite)
      .map(o => o.id)
      .includes(lane.id);
  };

  return (
    <>
      <Subscription subscription={GET_LANES} variables={{ customerId: customerId }}>
        {({ loading, error, data }) => {
          if (customerId === 0) {
            return (
              <>
                <div className={cls.paper}>
                  <Typography>Select a Customer from the Dropdown</Typography>
                </div>
              </>
            );
          }
          if (loading) return <Loading />;
          if (error) {
            log && console.log(`Failed to retrieve lanes:`, error);
            toast.error(`Failed to retrieve lanes`);
          }
          if (data && data.lanes) {
            let lanes = data.lanes.length > 0 ? data.lanes : [];
            let favoriteLanes = lanes.filter(l => l.favorite === true);

            return (
              <>
                <div className={cls.paper}>
                  <div className={cls.lane}>
                    <LocationSelect
                      valid={props.validation && props.validation.pickup ? props.validation.pickup : false}
                      locationData={pickup}
                      onChange={handlePickup}
                      label='Pickup Location'
                      subscriptionSkip={subscriptionSkip}
                      priceAndDistanceConfigured={priceAndDistanceConfigured}
                      customerId={customerId}
                    />

                    {pickup || delivery ? (
                      <div className={cls.actions}>
                        <div className={cls.action}>
                          <Tooltip placement='top' title='Swap Locations'>
                            <Icon className={cls.swapIcon} onClick={() => handleSwapLocations()}>
                              swap_horiz
                            </Icon>
                          </Tooltip>
                        </div>
                        {pickup && delivery && lane && lane.id ? (
                          <div className={cls.action}>
                            <Tooltip
                              placement='top'
                              title={laneIsFavorited(lane, favoriteLanes) ? `Unfavorite Lane` : `Favorite Lane`}
                            >
                              <Icon
                                className={laneIsFavorited(lane, favoriteLanes) ? cls.favIconActive : cls.favIcon}
                                onClick={() => handleLaneFavorite(favoriteLanes)}
                              >
                                {laneIsFavorited(lane, favoriteLanes) ? `favorite` : `favorite_border`}
                              </Icon>
                            </Tooltip>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className={cls.spacer} />
                    )}

                    <LocationSelect
                      valid={props.validation && props.validation.delivery ? props.validation.delivery : false}
                      locationData={delivery}
                      onChange={handleDelivery}
                      label='Delivery Location'
                      subscriptionSkip={subscriptionSkip}
                      priceAndDistanceConfigured={priceAndDistanceConfigured}
                      customerId={customerId}
                    />
                  </div>

                  {priceAndDistanceConfigured ? (
                    <div className={cls.laneInfo}>
                      {doesLaneExist ? <ExistingLaneEstimates /> : null}
                      {isSubscriptionRendering ? <NewLaneEstimates /> : null}
                    </div>
                  ) : null}

                  <FavoriteSelect
                    expand={expand}
                    handleExpand={handleExpand}
                    favoriteLanes={favoriteLanes}
                    handleFavoriteSelect={handleFavoriteSelect}
                  />
                </div>
              </>
            );
          } else {
            return <div>Error Finding Lanes</div>;
          }
        }}
      </Subscription>
    </>
  );
}

////////// GRAPHQL //////////
const GET_LANES = gql`
  subscription get_lanes($customerId: bigint!) {
    lanes(
      where: { customer_id: { _eq: $customerId }, active: { _eq: 1 } }
      order_by: [{ favorite: desc }, { description: asc }]
    ) {
      id
      description
      favorite
      pickup {
        id
        name
        nickname
        address
        email
        phone
      }
      delivery {
        id
        name
        nickname
        address
        email
        phone
      }
      delivery_inspection_sec
      duration_sec
      pickup_inspection_sec
      return_ride_wait_sec
    }
  }
`;

const GET_RATE_RULE = gql`
  query getRateRule($customerId: bigint!, $distance: numeric!, $class: String!) {
    raterules(
      where: {
        customer_id: { _eq: $customerId }
        distance_start: { _lte: $distance }
        distance_end: { _gte: $distance }
        class: { _like: $class }
        raterulegroup: { begin_date: { _lt: "now()" }, end_date: { _gt: "now()" } }
      }
    ) {
      id
      active
      class
      customer_id
      distance_end
      distance_start
      type
      rate
      raterulegroup {
        id
        begin_date
        end_date
      }
    }
  }
`;

const UPDATE_LANE_FAVORITE = gql`
  mutation update_lane_favorite($id: bigint!, $fav: Boolean!) {
    update_lanes(where: { id: { _eq: $id } }, _set: { favorite: $fav }) {
      affected_rows
      returning {
        id
        description
        favorite
      }
    }
  }
`;
