import React from 'react';
import { useData } from '../../../DataProvider';
import gql from 'graphql-tag';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';
import * as Sentry from "@sentry/react";
import { toast } from 'react-toastify';
import { useTools } from '../../../components/hooks/useTools';

const log = false;

////////// COMPONENT //////////
export default function MoveCancelModal(props) {
  const ctx = useData();
  const { sdk } = ctx;

  const { goToRoute } = useTools();

  const { open, onClose, moveInput } = props;

  const { REACT_APP_ADMIN_SD } = process.env

  const createNotification = async () => {
    const variables = {
      type: 'action',
      title: `Move ${moveInput.id} Pending Cancel`,
      body: `Move ${moveInput.id} is pending cancel. Click the link in the notes below to visit the details page and update the cancel status.`,
      createdat: 'now()',
      createdby: 'move-cancel.dealer',
      notes: `[Click to go to Move Details](https://${REACT_APP_ADMIN_SD}.hopdrive.io/moves/${moveInput.id})`
    };
    const res = await sdk.notifications.create(variables);
    if (res){
      console.log(res);
    } else console.log('no dice')
    
};

  const handleSubmit = async () => {
    try {
      await createNotification()
    } catch (err) {
      console.log(err)
    }
    ctx.apolloClient.mutate({
      mutation: REQUEST_MOVE_CANCEL,
      variables: { id: moveInput.id },
      onError: err => {Sentry.captureException(err); toast.error(`Failed to request move cancelation`)}
    }).then(res => {
      if (res.data.update_moves) {
        const moveRes = res.data.update_moves.returning[0];
        console.log(moveRes);
        log && console.log(`>> UPDATED move #${moveRes.id} with a cancel_status of '${moveRes.cancel_status}'.`);
        toast.success(`Cancel request successful for Move #${moveRes.id}.`);
        handleClose();
      }
    }).catch(err => {
      console.log(`Error updating move:`, err);
    });
  }

  const handleClose = () => {
    if (onClose) onClose();
  }

  return (<>
    <Modal open={open}>

      <ModalHeader handleClose={handleClose}>Cancelation Request for Move #{moveInput && moveInput.id}</ModalHeader>

      {moveInput && moveInput.appointment && moveInput.appointment.id ? (
        <>
        <ModalContent subtitle={moveInput.status === null || moveInput.status === 'dispatched' ? `This move is linked to a prepaid consumer appointment. Please cancel the appointment first, which will automatically cancel this move.` : `Since this move is linked to a prepaid consumer appointment and has already been started, it cannot be canceled at this time. If you have any questions, please contact your Hopdrive representative.`} />
        <ModalFooter>
          <ModalAction onClick={() => goToRoute(`/appointments/${moveInput.appointment.id}`)} color="secondary">View Appointment</ModalAction>
          <ModalAction onClick={() => handleClose()} color="secondary">Close</ModalAction>
        </ModalFooter>
        </>
      ) : (
        <>
        <ModalContent subtitle={`Are you sure you want to cancel this move? Your cancelation will be pending until reviewed by a HopDrive dispatcher.`} />

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
          <ModalAction onClick={() => handleClose()} color="secondary">Close</ModalAction>
        </ModalFooter>
        </>
      )}

    </Modal>
  </>)
}

////////// GRAPHQL //////////
const REQUEST_MOVE_CANCEL = gql`
mutation request_move_cancel($id: bigint!) {
  update_moves(where: {id: {_eq: $id}}, _set: {cancel_status: "pending"}) {
    affected_rows
    returning {
      id
      cancel_status
      status
    }
  }
}
`;