import React from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Divider,
  Typography,
  List,
  // ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Icon,
  Tooltip,
  Chip,
} from '@material-ui/core';
import { palette, spacing, typography } from '@material-ui/system';
import Routes from '../Routes';
import CustomerSelect from './reusable/selectors/CustomerSelect';
import ContactModal from './ContactModal';
import './../index.css';
import 'typeface-roboto';
import {
  getUserRole,
  getAllowedCustomers,
  getCustomerId,
  getAllowedPayees,
  getUserEmail,
  getUserId,
} from './utils/authHelper';
import { useData } from '../DataProvider';
import { getAuth, signOut } from 'firebase/auth';
import sdk from '@hopdrive/sdk';
const logo = require('../static/logo-banner-light.svg');

let log = false;

const production = process.env.REACT_APP_ENV === `production` ? true : false;

const drawerWidth = 320;

const styles = theme => ({
  avatar: {
    display: 'inline-flex',
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  smallHide: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  appBar_prod: {
    zIndex: theme.zIndex.drawer + 1,
    maxHeight: '64px',
    backgroundColor: theme.palette.nav.prod,
    boxShadow: '0px 0px 16px #00000032',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBar_test: {
    zIndex: theme.zIndex.drawer + 1,
    maxHeight: '64px',
    backgroundColor: theme.palette.nav.test,
    boxShadow: '0px 0px 16px #00000032',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  titleChip: {
    paddingLeft: 2,
    border: `1px solid ${theme.palette.text.contrastFade}`,
    marginLeft: theme.spacing(2),
    color: theme.palette.text.contrastFade,
    '& .MuiChip-icon': {
      color: theme.palette.text.contrastFade,
      transition: '0.15s',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.text.contrast}`,
      color: theme.palette.text.contrast,
      '& .MuiChip-icon': {
        color: theme.palette.text.contrast,
      },
    },
    cursor: 'pointer',
    transition: '0.15s',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  titleChipIcon: {
    fontSize: 20,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 320,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: 320,
    },
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },

  content: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  body: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    maxHeight: '100%',
  },
  navPad: {
    position: 'relative',
    width: '100%',
    minHeight: '64px',
    maxHeight: '64px',
  },
  page: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
});

function Layout(props) {
  const firebase = getAuth();
  const ctx = useData();
  const history = useHistory();
  const { classes } = props;

  const [open, setOpen] = React.useState(false);
  const [userRole, setUserRole] = React.useState(null);
  const [allowedCustomers, setAllowedCustomers] = React.useState([]);
  const [customerSelectType, setCustomerSelectType] = React.useState(null);
  const [contactModalOpen, setContactModalOpen] = React.useState(false);
  const [hasAppointmentPermissions, setHasAppointmentPermissions] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getConfig = async customerId => {
    const config = await sdk.configs.getCustomerConfig(customerId);
    if (config && config.appointments && config.appointments.enabled && config.appointments.enabled === true) {
      setHasAppointmentPermissions(true);
    } else setHasAppointmentPermissions(false)
  };

  const writeLogoutEvent = async (email, action, token) => {
    const userId = await getUserId();
    try {
      const eventlogRes = await axios({
        method: `POST`,
        url: `/.netlify/functions/write-user-event-logs`,
        data: {
          actorEmail: email,
          action: 'user.logout',
          affectedUserId: userId,
        },
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      });
      if (eventlogRes && eventlogRes.status && eventlogRes.status !== 200) {
        console.error(`Failed to write user eventlog: ${eventlogRes}`);
        Sentry.captureException(`Failed to write user eventlog: ${eventlogRes}`);
      }
    } catch (err) {
      console.error(`Failed to write user eventlog:`, err);
      Sentry.captureException(`Failed to write user eventlog: ${err}`);
    }
  };

  const userLogout = async () => {
    if (ctx.firebaseUser) {
      const email = getUserEmail();
      await writeLogoutEvent(email, 'user.logout', firebase.currentUser.accessToken);
      signOut(firebase);
      ctx.setFirebaseUser(null);
    }
    window.location.reload();
  };

  React.useEffect(() => {
    const getRole = async () => {
      let role = await getUserRole();
      setUserRole(role);
    };

    const getCustomers = async () => {
      let customers = await getAllowedCustomers();
      let customer = await getCustomerId();
      let payees = await getAllowedPayees();
      customer = customer ? parseInt(customer) : null;
      let allCustomers;
      if (customer && customers && customers.length > 0 && (!payees || !payees.length > 0)) {
        allCustomers = !customers.includes(customer) ? customers.concat(customer) : customers;
      } else if (customer && customers && customers.length > 0 && payees && payees.length > 0) {
        let customerRoundup = customers;
        payees.forEach(payee => {
          if (!customers.includes(payee)) {
            customerRoundup.push(payee);
          }
        });
        if (!customerRoundup.includes(customer)) {
          customerRoundup.push(customer);
        }
        allCustomers = customers.concat(payees, customer);
      } else allCustomers = [customer];
      setAllowedCustomers(allCustomers);
    };

    if (!ctx.firebaseUser) {
      history.push('/login');
    }

    if (ctx.firebaseUser && ctx.apolloClient) {
      getRole();
      getCustomers();
    }
  }, [ctx.firebaseUser, ctx.apolloClient]);

  React.useEffect(() => {
    if (ctx.customerOverride || ctx.customerId) {
      const customerId = parseInt(ctx.customerOverride || ctx.customerId);
      getConfig(customerId);
    }
  }, [ctx.customerOverride, ctx.customerId])

  React.useEffect(() => {
    if (allowedCustomers && allowedCustomers.length >= 1 && userRole) {
      if (userRole === 'admin') {
        setCustomerSelectType('admin');
      } else if (userRole === 'dealer-super-admin' || userRole === 'dealer-admin') {
        setCustomerSelectType('dealer-admin');
      } else setCustomerSelectType('dealer');
    }
  }, [allowedCustomers, userRole]);

  return (
    <>

    <ContactModal
      open={contactModalOpen}
      onClose={() => setContactModalOpen(!contactModalOpen)}
    />

    <div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position='absolute'
          className={classNames(production ? classes.appBar_prod : classes.appBar_test, open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!open} className={classes.toolbar}>
            {!open && (
              <Tooltip title='Menu'>
                <IconButton
                  style={{ marginRight: '8px' }}
                  color='inherit'
                  aria-label='Open drawer'
                  onClick={handleDrawerOpen}
                  className={classNames(classes.menuButton)}
                >
                  <Icon>menu</Icon>
                </IconButton>
              </Tooltip>
            )}

            {!open && (
              <Link to='/'>
                <img style={{ marginTop: '12px' }} alt='logo-hopdrive' width='180px' src={logo} />
              </Link>
            )}

            <div className={classes.title} />

            <div className={classes.smallHide}>
              <Tooltip title='Click to contact your Hopdrive team'>
                <IconButton onClick={() => setContactModalOpen(true)}>
                  <Icon style={{ color: '#fff' }}>contact_support</Icon>
                  <Typography style={{ color: '#fff', marginLeft: '10px' }}>Contact Support</Typography>
                </IconButton>
              </Tooltip>
            </div>

            {ctx.firebaseUser && customerSelectType === 'admin' ? (
              <CustomerSelect />
            ) : ctx.firebaseUser && customerSelectType === 'dealer-admin' ? (
              <CustomerSelect customersArray={allowedCustomers} />
            ) : null}

            {ctx.firebaseUser && (
              <div>
                <Link to='/profile'>
                  <Tooltip title='Profile'>
                    <IconButton>
                      <Icon style={{ color: '#fff' }}>account_circle</Icon>
                    </IconButton>
                  </Tooltip>
                </Link>
                <Tooltip title='Sign Out'>
                  <IconButton
                    onClick={() => {
                      userLogout();
                    }}
                  >
                    <Icon style={{ color: '#fff' }}>exit_to_app</Icon>
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <div onClick={handleDrawerClose}>
          <Drawer
            // variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, open && classes.drawerPaperClose),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <Icon>chevron_left</Icon>
              </IconButton>
            </div>

            <Divider />

            {!ctx.firebaseUser && (
              <List>
                <ListItem button component={Link} to='/login' onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <Icon>home</Icon>
                  </ListItemIcon>
                  <ListItemText primary='Login' />
                </ListItem>
              </List>
            )}

            {ctx.firebaseUser && (
              <>
                <List>
                  <ListItem button component={Link} to='/' onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>dashboard</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Dashboard' />
                  </ListItem>

                  <ListItem button component={Link} to='/moves/add' onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>queue</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Add Moves' />
                  </ListItem>

                  <ListItem button component={Link} to='/moves/import' onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>upload</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Import Moves CSV' />
                  </ListItem>
                </List>

                <Divider />

                <List>
                  <ListItem button component={Link} to='/moves' onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>directions_car</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Moves' />
                  </ListItem>

                  <ListItem button component={Link} to='/locations' onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>store_mall_directory</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Locations' />
                  </ListItem>
                </List>

                <Divider />

                <List>
                  <ListItem button component={Link} to='/billing' onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>monetization_on</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Billing' />
                  </ListItem>

                  <ListItem button component={Link} to='/invoices' onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>receipt</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Invoices' />
                  </ListItem>
                </List>

                <Divider />

                <List>
                  {userRole && userRole === 'dealer-super-admin' && (
                    <ListItem button component={Link} to='/users' onClick={handleDrawerClose}>
                      <ListItemIcon>
                        <Icon>people</Icon>
                      </ListItemIcon>
                      <ListItemText primary='Users' />
                    </ListItem>
                  )}

                  {userRole && userRole.includes('admin') && (
                    <ListItem button component={Link} to='/overview' onClick={handleDrawerClose}>
                      <ListItemIcon>
                        <Icon>calendar_today</Icon>
                      </ListItemIcon>
                      <ListItemText primary='Organization Overview' />
                    </ListItem>
                  )}

                  <ListItem button component={Link} to='/appraisals' onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <Icon>assignment_turned_in</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Appraisals' />
                  </ListItem>
                  {hasAppointmentPermissions && (
                    <ListItem button component={Link} to='/appointments' onClick={handleDrawerClose}>
                      <ListItemIcon>
                      <Icon>calendar_month</Icon>
                    </ListItemIcon>
                    <ListItemText primary='Appointments' />
                    </ListItem>
                  )}
              </List>
              </>
            )}
          </Drawer>
        </div>
        <main className={classes.content}>
          <div style={{ marginTop: '100px' }} id='login' hidden={ctx.firebaseUser}></div>
          <div className={classes.body}>
            <div className={classes.navPad} />
            <div className={classes.page}>
              <Routes />
            </div>
          </div>
        </main>
        <ToastContainer position='top-center' />
      </div>
    </div>
    </>
  );
}
//////////////////////// EXPORT ////////////////////////

export default withStyles(styles)(withRouter(Layout));
