import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Container, Grid, Typography, Tooltip, IconButton, Button, Icon, Chip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useTools } from '../../hooks/useTools';
import sdk from '@hopdrive/sdk';
import AppointmentStatusTracker from './AppointmentStatusTracker';
import { toast } from 'react-toastify';
import { gql } from 'graphql-tag';
import { getUserEmail } from '../../utils/authHelper';
import { Alert } from '@material-ui/lab';
import { withRouter } from 'react-router-dom';
import AppointmentAddForm from './AppointmentAddForm';
import moment from 'moment';
////////// COMPONENT //////////
function AppointmentDetailsContent(props) {
  const { appointment } = props;
  const cls = useStyles();
  const { getCleansedPhoneNumber, goToRoute } = useTools();

  const [editMode, setEditMode] = React.useState(false);
  const [expired, setExpired] = React.useState(false);

  const checkExpiration = apptTime => {
    const currentTime = moment();
    const cutoff = moment(apptTime).subtract(90, 'minutes');

    if (currentTime.isAfter(cutoff)) {
      return false;
    }

    let start = moment(apptTime).startOf('minute').add(90, 'minutes');
    return currentTime.isBefore(start);
  };

  const getPaymentDeadline = () => {
    if (appointment.appointment_time) {
      return moment(appointment.appointment_time).subtract(90, 'minutes').format('MM/DD/YYYY, h:mm A');
    }
    return 'N/A';
  };

  React.useEffect(() => {
    if (appointment.appointment_time) {
      const validTime = checkExpiration(appointment.appointment_time);
      if (!validTime) {
        setExpired(true);
      } else {
        setExpired(false);
      }
    }
  }, [appointment]);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const getRefNumLabel = () => {
    const enrichedConfig = sdk.configs.enrichCustomerConfig(
      sdk.utilities.getPropValue(appointment.customer, `config`),
      sdk.utilities.getPropValue(appointment.customer, `organization.config`)
    );
    if (enrichedConfig && enrichedConfig.appointments && enrichedConfig.appointments.ref_num_label) {
      return enrichedConfig.appointments.ref_num_label
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    return 'Reference #';
  };

  const getFormattedVehicleFromAppointment = appointment => {
    const { vehicle_year, vehicle_make, vehicle_model, vehicle_color } = appointment;
    if (vehicle_year && vehicle_make && vehicle_model) {
      return `${vehicle_color ? vehicle_color : ''} ${vehicle_year} ${vehicle_make} ${vehicle_model}`;
    }
    return 'N/A';
  };

  const getPaymentStatusFromAppointment = appointment => {
    if (appointment.status === 'paying' || appointment.status === 'ready') {
      return (
        <div className={cls.chipContainer}>
          <Chip label='Pending' color='default' size='small' variant='outlined' />
        </div>
      );
    } else if (appointment.status === 'paid') {
      return (
        <div className={cls.chipContainer}>
          <Chip label='Paid' color='secondary' size='small' variant='outlined' />
        </div>
      );
    } else if (appointment.status === 'failed') {
      return (
        <div className={cls.chipContainer}>
          <Chip label='Failed' color='primary' size='small' variant='outlined' />
        </div>
      );
    } else return <Typography className={cls.valTxt}>-</Typography>;
  };

  const handleCancelAppointment = async () => {
    try {
      const res = await sdk.gql.mutation(CANCEL_APPOINTMENT, {
        id: appointment.id,
        updatedby: getUserEmail(),
      });
      if (res && res.success) {
        toast.success('Appointment canceled successfully!');
      } else {
        toast.error('Failed to cancel appointment!');
      }
    } catch (err) {
      console.error('Failed to cancel appointment:', err);
      toast.error('Failed to cancel appointment!');
    }
  };

  const handleResendOffer = async () => {
    //update appointment offered_at to now and offered_by to current user. This will trigger an event to resend the SMS
    const res = await sdk.gql.mutation(UPDATE_APPOINTMENT, {
      id: appointment.id,
      offered_by: getUserEmail(),
    });
    if (res && res.success) {
      toast.success('Offer SMS reinitated successfully!');
    } else {
      toast.error('Failed to reinitiate offer SMS!');
    }
  };

  if (editMode) {
    return <AppointmentAddForm {...props} appointment={appointment} editMode={editMode} setEditMode={setEditMode} />;
  }

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <div className={cls.break_lg} />
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <IconButton className={cls.iconBtn} onClick={() => goToRoute('/appointments')}>
              <Icon>arrow_back</Icon>
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography className={cls.head}>Appointment Details</Typography>
          </Grid>
          {appointment.status === 'ready' || appointment.status === 'failed' ? (
            <Grid item>
              <Tooltip
                placement='top'
                title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the move`}
              >
                <IconButton className={cls.iconBtn} onClick={() => handleEditMode()}>
                  <Icon>{editMode ? `lock_open` : `lock`}</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>

        <div className={cls.break_lg} />

        {appointment.status !== 'paid' && appointment.status !== 'canceled' && !expired ? (
          <div className={cls.pendingContainer}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item>
                <ErrorIcon className={cls.pendingIcon} />
              </Grid>
              <Grid item xs>
                <Typography variant='body1' className={cls.pendingText}>
                  Pay by {getPaymentDeadline()}
                </Typography>
                <Typography variant='body2' style={{ marginTop: '8px' }}>
                  In order to ensure time for scheduling and driver assignment, the consumer must complete payment by
                  the date and time above.
                </Typography>
                <Typography variant='body2'>
                  If payment isn't completed by then, you can set the appointment time further ahead by clicking the
                  lock icon in the upper right.
                </Typography>
              </Grid>
            </Grid>
          </div>
        ) : null}

        {appointment.status !== 'paid' && appointment.status !== 'canceled' && expired ? (
          <div className={cls.expiredContainer}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item>
                <ErrorIcon className={cls.expiredIcon} />
              </Grid>
              <Grid item xs>
                <Typography variant='body1' className={cls.expiredText}>
                  Offer expired!
                </Typography>
                <Typography variant='body2' style={{ marginTop: '8px' }}>
                  In order to ensure time for scheduling, payment must be completed within 90 minutes of the scheduled
                  appointment time.
                </Typography>
                <Typography variant='body2'>
                  If you would still like to proceed with this appointment, please click the lock icon in the upper
                  right to update the appointment time.
                </Typography>
              </Grid>
            </Grid>
          </div>
        ) : null}

        <AppointmentStatusTracker appointment={appointment} />

        <div className={cls.break_lg} />
        <div className={cls.main}>
          <div className={cls.paper}>
            {/* Header Section */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Tooltip placement='top-start' title='Appointment ID'>
                  <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                    {appointment.id ? `Appointment #${appointment.id}` : `No Appointment ID`}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip placement='top' title='Appointment Date'>
                  <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>
                    {dayjs(appointment.appointment_time).format(`MM/DD/YYYY h:mm A`)}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Tooltip placement='top-end' title='Appointment Status'>
                  <Typography className={cls.headTxt}>
                    {appointment.status.charAt(0).toUpperCase() + appointment.status.slice(1)}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>

            <div className={cls.break} />

            {/* Details Section */}
            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6}>
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Consumer Name:&nbsp;&nbsp;</Typography>
                  <>
                    <Typography className={cls.valTxt}>{appointment.consumer_name || '-'}</Typography>
                  </>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Consumer Phone:&nbsp;&nbsp;</Typography>
                  <>
                    <Typography className={cls.valTxt}>
                      {getCleansedPhoneNumber(appointment.consumer_phone) || '-'}
                    </Typography>
                  </>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Pickup:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {appointment.pickup && appointment.pickup.address ? appointment.pickup.address : '-'}
                  </Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Delivery:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {appointment.delivery && appointment.delivery.address ? appointment.delivery.address : '-'}
                  </Typography>
                </div>
              </Grid>

              {/* Right Column */}
              <Grid item xs={6}>
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>{getRefNumLabel()}:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {appointment.config && appointment.config.claim_number ? appointment.config.claim_number : '-'}
                  </Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>{getFormattedVehicleFromAppointment(appointment)}</Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>VIN:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>{appointment.vehicle_vin || '-'}</Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Notes:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>{appointment.driver_notes || '-'}</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={cls.break_lg} />

        {/* Metadata Section */}
        <div className={cls.main}>
          <div className={cls.paper}>
            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6}>
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Offered:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {appointment.offered_at && appointment.offered_by
                      ? `${dayjs(appointment.offered_at).format(`MM/DD/YYYY h:mm A`)} by ${appointment.offered_by}`
                      : '-'}
                  </Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Created:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {appointment.createdat && appointment.createdby
                      ? `${dayjs(appointment.createdat).format(`MM/DD/YYYY h:mm A`)} by ${appointment.createdby}`
                      : '-'}
                  </Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Rooftop:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {appointment && appointment.customer && appointment.customer.name ? appointment.customer.name : '-'}
                  </Typography>
                </div>
              </Grid>

              {/* Right Column */}
              <Grid item xs={6}>
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Offer Link:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {appointment.config && appointment.config.offer_link ? (
                      <>
                        <a
                          href={appointment.config.offer_link}
                          target='_blank'
                          rel='noopener noreferrer'
                          className={cls.link}
                        >
                          View Offer
                        </a>
                        <Tooltip placement='top' title='Copy Offer Link'>
                          <IconButton
                            size='small'
                            onClick={() => navigator.clipboard.writeText(appointment.config.offer_link)}
                            className={cls.copyButton}
                          >
                            <Icon>content_copy</Icon>
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      '-'
                    )}
                  </Typography>
                </div>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Payment Status:&nbsp;&nbsp;</Typography>
                  {getPaymentStatusFromAppointment(appointment)}
                </div>

                {appointment.status === 'failed' && appointment.payment_failure_reason ? (
                  <>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Failure Reason:&nbsp;&nbsp;</Typography>
                      <Typography className={cls.valTxt}>{appointment.payment_failure_reason}</Typography>
                    </div>
                  </>
                ) : null}

                {appointment.config &&
                appointment.config.emailsToNotify &&
                appointment.config.emailsToNotify.length > 0 ? (
                  <>
                    <div className={cls.lineBreak} />
                    <div className={cls.listItem}>
                      <Typography className={cls.keyTxt}>Emails Notified:&nbsp;&nbsp;</Typography>
                      <Typography className={cls.valTxt}>{appointment.config.emailsToNotify.join(', ')}</Typography>
                    </div>
                  </>
                ) : null}
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Associated Move:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {appointment.move_id ? (
                      <a href={`/moves/${appointment.move_id}`} className={cls.link}>
                        Move {appointment.move_id}
                      </a>
                    ) : (
                      '-'
                    )}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={cls.break} />

        <Grid container justifyContent='flex-end'>
          {!appointment.move || (appointment.move.status === null || appointment.move.status === 'dispatched') ? (
              <Grid item>
              <Button className={cls.cancelBtn} color='primary' onClick={handleCancelAppointment}>
                Cancel Appointment
              </Button>
            </Grid>
          ) : null}

            <Grid item>
              <Button className={cls.resentBtn} color='primary' onClick={handleResendOffer}>
                Resend Offer
              </Button>
            </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withRouter(AppointmentDetailsContent);

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  chipContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  iconBtn: {
    width: 40,
    height: 40,
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  break_lg: {
    width: '100%',
    height: theme.spacing(3),
  },
  nakedValInput: {
    width: '100%',
    minHeight: 17,
    maxHeight: 17,
    padding: 0,
    background: '#00000010',
    textAlign: 'right',
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  cancelBtn: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
    color: '#fff',
    background: '#000000',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
      background: '#333333',
    },
    transition: '0.2s',
  },
  resentBtn: {
    marginTop: theme.spacing(3),
    color: '#fff',
    background: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
      background: theme.palette.primary.dark,
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  expiredContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffebee',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
    border: '1px solid #e0e0e0',
  },
  expiredIcon: {
    color: '#B71C1C',
    marginRight: theme.spacing(1.5),
    fontSize: 20,
  },
  expiredText: {
    color: '#B71C1C',
    fontWeight: 500,
  },
  pendingContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
    border: '1px solid #e0e0e0',
  },
  pendingIcon: {
    color: '#757575',
    marginRight: theme.spacing(1.5),
    fontSize: 20,
  },
  pendingText: {
    color: '#616161',
    fontWeight: 500,
  },
  copyButton: {
    marginLeft: theme.spacing(1),
  },
}));

const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($id: bigint!, $updatedby: String) {
    update_appointments(
      where: { id: { _eq: $id } }
      _set: { status: "canceled", updatedby: $updatedby, updatedat: "now()" }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_APPOINTMENT = gql`
  mutation UpdateAppointment($id: bigint!, $offered_by: String) {
    update_appointments(where: { id: { _eq: $id } }, _set: { offered_at: "now()", offered_by: $offered_by }) {
      affected_rows
    }
  }
`;
