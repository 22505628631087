import React from 'react';

import { makeStyles, Grid, Typography, useTheme, Tooltip } from '@material-ui/core';

const log = false;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
    lg_stepCombined: {
        position: 'relative',
        height: '100%',
        borderRadius: '48px',
        background: '#00000050',
      },
  lg_step1: {
    position: 'relative',
    height: '100%',
    borderRadius: '48px 0 0 48px',
    marginRight: theme.spacing(1.5),
    background: '#00000050',
    '&::after': {
      content: `content`,
      position: 'absolute',
      right: 0,
      background: '#00000050',
    },
  },
  lg_step2: {
    position: 'relative',
    height: '100%',
    background: '#00000050',
  },
  lg_step3: {
    position: 'relative',
    height: '100%',
    borderRadius: '0 48px 48px 0',
    marginLeft: theme.spacing(1.5),
    background: '#00000050',
  },
  lg_stepTxt: {
    position: 'absolute',
    top: '32.5%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: '21px',
    fontWeight: 500,
  },
}));

////////// COMPONENT //////////
export default function AppointmentStatusTracker(props) {
  const theme = useTheme();
  const cls = useStyles();
  const { appointment } = props;

  let offeredAt = appointment.offered_at;
  let status = appointment.status;
  let moveCreated = appointment.move_id;

  let label = ``;
  let step = 0;
  let pulse = false;
  let activeStep = 1;
  let tooltipText = ``;

  // Determine the current step and which step should pulse
  if ((status === 'new' || status === 'pending' || status === 'ready') && !offeredAt) {
    step = 1;
    activeStep = 1;
    pulse = true;
    tooltipText = `Appointment has been created.`;
  } else if (offeredAt && status === 'ready') {
    step = 1;
    activeStep = 1;
    pulse = false;
    tooltipText = `Offer has been sent to the consumer and is awaiting payment.`;
  } else if (status === 'paying') {
    step = 2;
    activeStep = 2;
    pulse = true;
    tooltipText = `Payment is being processed.`;
  } else if (status === 'paid' || status === 'failed') {
    step = 2;
    if (status === 'paid') {
      step = 3;
      activeStep = 3;
      pulse = !moveCreated;
      tooltipText = `Payment has been collected and Hopdrive is creating the move.`;
    } else {
      step = 2;
      activeStep = 2;
      pulse = false;
      tooltipText = `Payment failed. The customer may continue to retry.`;
    }
  } else if (moveCreated) {
    step = 3;
    activeStep = 3;
    pulse = false;
    tooltipText = `Appointment confirmed and move created`;
  } else if (status === 'canceled') {
    step = 3;
    activeStep = 3;
    pulse = false;
    tooltipText = `Appointment canceled`;
  }

  log && console.log(`Label:`, label);

  let t1Hue = step >= 1 ? theme.palette.primary.main : theme.palette.fade[3];
  let t2Hue = step >= 2 ? theme.palette.primary.main : theme.palette.fade[3];
  let t3Hue = step >= 3 ? theme.palette.primary.main : theme.palette.fade[3];
  let t1Pulse = pulse && activeStep === 1 ? `pulse 1s infinite` : 'none';
  let t2Pulse = pulse && activeStep === 2 ? `pulse 1s infinite` : 'none';
  let t3Pulse = pulse && activeStep === 3 ? `pulse 1s infinite` : 'none';

  if (status === 'canceled') {
    t1Hue = theme.palette.fade[3];
    t2Hue = theme.palette.fade[3];
    t3Hue = theme.palette.fade[3];
    return (
        <div className={cls.lg_tracker}>
            <Grid container spacing={0} wrap='nowrap' style={{ height: '64px' }}>
                <Grid item xs={12}>
                    <Tooltip title='Appointment canceled' placement='top' arrow>
                        <div className={cls.lg_stepCombined} style={{ background: theme.palette.fade[3], boxShadow: `0 0 0 0 ${theme.palette.fade[3]}`, animation: 'none'}}>
                            <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>CANCELED</Typography>
                        </div>
                    </Tooltip>

                </Grid>
            </Grid>
        </div>
    )
  }

  return (
    <>
      <div className={cls.lg_tracker}>
        <Grid container spacing={0} wrap='nowrap' style={{ height: '64px' }}>
          <Grid item xs={2}>
            <Tooltip title={activeStep === 1 ? tooltipText : ''} placement='top' arrow>
              <div
                className={cls.lg_step1}
                style={{ background: t1Hue, boxShadow: `0 0 0 0 ${t1Hue}`, animation: t1Pulse }}
              >
                <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>
                  OFFER
                </Typography>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={5}>
            <Tooltip title={activeStep === 2 ? tooltipText : ''} placement='top' arrow>
              <div
                className={cls.lg_step2}
                style={{ background: t2Hue, boxShadow: `0 0 0 0 ${t2Hue}`, animation: t2Pulse }}
              >
                <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>
                  PAYMENT
                </Typography>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={5}>
            <Tooltip title={activeStep === 3 ? tooltipText : ''} placement='top' arrow>
              <div
                className={cls.lg_step3}
                style={{ background: t3Hue, boxShadow: `0 0 0 0 ${t3Hue}`, animation: t3Pulse }}
              >
                <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>
                  CONFIRMATION
                </Typography>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
