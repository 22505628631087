import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/utils/PrivateRoute';

import LoginPage from './components/pages/Login/FirebaseLogin';
import ProfilePage from './components/pages/Profile';
import Dashboard from './components/pages/Dashboard';
import MoveImport from './components/pages/moveImport/MoveImport';
import MovePlanner from './components/pages/MovePlanner';
import Moves from './components/pages/Moves';
import MoveDetails from './components/pages/MoveDetails';
import Locations from './components/pages/Locations';
import LocationDetails from './components/pages/LocationDetails';
import Appraisals from './components/pages/Appraisals';
import AppraisalDetails from './components/pages/AppraisalDetails';
import Appointments from './components/pages/Appointments';
import AppointmentDetails from './components/pages/AppointmentDetails';
import Billing from './components/pages/Billing';
import ARInvoices from './components/pages/ARInvoices';
import Invoices from './components/pages/Invoices/Invoices';
import OrganizationOverview from './components/pages/OrganizationOverview';
import UsersPage from './components/pages/Users/Users';
import UserAddPage from './components/pages/UserAdd/UserAddWrapper';
import UserDetailsPage from './components/pages/UserDetails/UserDetailsWrapper';
import DefaultEmptyFallback from './components/reusable/Fallbacks/DefaultEmptyFallback';
import { useData } from './DataProvider.js'

export default function Routes(props) {

  const ctx = useData();

  return (
    <Switch>
      <Route exact path='/login' component={LoginPage} />
      <PrivateRoute exact path='/' component={Dashboard} />
      <PrivateRoute exact path='/profile' component={ProfilePage} />
      <PrivateRoute exact path='/moves/add' component={MovePlanner} />
      <PrivateRoute exact path='/moves/import' component={MoveImport} />
      <PrivateRoute exact path='/moves' component={Moves} />
      <PrivateRoute exact path='/moves/:id/' component={MoveDetails} />
      <PrivateRoute exact path='/locations' component={Locations} />
      <PrivateRoute exact path='/locations/:id/' component={LocationDetails} />
      <PrivateRoute exact path='/appraisals' component={Appraisals} />
      <PrivateRoute exact path='/appraisals/:id/' component={AppraisalDetails} />
      <PrivateRoute exact path='/appointments' component={Appointments} />
      <PrivateRoute exact path='/appointments/add' component={AppointmentDetails} />
      <PrivateRoute exact path='/appointments/:id' component={AppointmentDetails} />
      <PrivateRoute exact path='/billing' component={Billing} />
      <PrivateRoute exact path='/invoices' component={Invoices} />
      <PrivateRoute exact path='/invoices2' component={ARInvoices} />
      <PrivateRoute exact path='/overview' component={OrganizationOverview} />
      {ctx.role === 'dealer-super-admin' ? (
        <>
          <PrivateRoute exact path='/users' component={UsersPage} />
          <PrivateRoute exact path='/users/add' component={UserAddPage} />
          <PrivateRoute exact path='/users/:id(\d+)' component={UserDetailsPage} />
        </>
      ) : (
        <DefaultEmptyFallback message='RESTRICTED ACCESS' />
      )}
    </Switch>
  );
}
