import React from 'react';
import { withRouter } from 'react-router';
import { Subscription, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { Typography} from '@material-ui/core';
import Loading from '../utils/Loading';
import AppointmentAddForm from './appointments/AppointmentAddForm';
import AppointmentDetailsContent from './appointments/AppointmentDetailsContent';
import { useData } from '../../DataProvider';
import sdk from '@hopdrive/sdk';
import DefaultEmptyFallback from '../reusable/Fallbacks/DefaultEmptyFallback';

const GET_APPOINTMENT = gql`
  subscription GetAppointment($id: bigint!) {
    appointments_by_pk(id: $id) {
      id
      appointment_time
      consumer_name
      consumer_phone
      consumer_at_pickup
      customer_id
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
      vehicle_vin
      status
      move_id
      offered_at
      offered_by
      createdat
      createdby
      config
      driver_notes
      payment_failure_reason
      payment_transaction_id
      customer {
        name
        config
        organization {
          config
        }
      }
      delivery {
        address
      }
      pickup {
        address
      }
      lane {
        id
      description
      favorite
      pickup {
        id
        name
        nickname
        address
        email
        phone
      }
      delivery {
        id
        name
        nickname
        address
        email
        phone
      }
      delivery_inspection_sec
      duration_sec
      pickup_inspection_sec
      return_ride_wait_sec
      }
      move {
        id
        status
      }
      workflowset {
        delivery_workflow_id
        pickup_workflow_id
        description
        id
        name
        public
        sla {
          description
          duration_hrs
          id
          label
          public
        }
      }
    }
  }
`;

const AppointmentDetails = (props) => {
  const isNewAppointment = props.match.path === '/appointments/add';
  const id = isNewAppointment ? null : parseInt(props.match.params.id);

  const ctx = useData();

  const [hasAppointmentPermissions, setHasAppointmentPermissions] = React.useState(false);

  const getConfig = async customerId => {
    const config = await sdk.configs.getCustomerConfig(customerId);
    if (config && config.appointments && config.appointments.enabled && config.appointments.enabled === true) {
      setHasAppointmentPermissions(true);
    } else setHasAppointmentPermissions(false)
  };

  React.useEffect(() => {
    const customerId = parseInt(ctx.customerOverride || ctx.customerId || 0);
    if (customerId) {
      getConfig(customerId);
    }
    
  }, [ctx.customerOverride, ctx.customerId]);

  if (!hasAppointmentPermissions) {
    return <DefaultEmptyFallback message='RESTRICTED ACCESS' />;
  }

  // If this is a new appointment, render the form immediately
  if (isNewAppointment) {
    return (
      <div>
        <AppointmentAddForm />
      </div>
    );
  }

  // Otherwise, fetch and display existing appointment
  return (
    <Subscription
      subscription={GET_APPOINTMENT}
      variables={{ id }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <Typography color="error">Error: {error.message}</Typography>;

        const appointment = data && data.appointments_by_pk ? data.appointments_by_pk : null;

        return (
          <AppointmentDetailsContent appointment={appointment} />
        );
      }}
    </Subscription>
  );
};

const AppointmentDetailsWithRouter = withRouter(AppointmentDetails);
const AppointmentDetailsWithRouterAndApollo = withApollo(AppointmentDetailsWithRouter);

export default AppointmentDetailsWithRouterAndApollo;
