import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Grid, Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notifications: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  spacer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: theme.spacing(2),
    minHeight: theme.spacing(2),
  },
  sub: {
    maxWidth: '640px',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-12px',
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-8px',
      fontSize: '12px',
    },
  },
}));

////////// COMPONENT //////////
export default function NotificationManagement(props) {
  const cls = useStyles();

  const { emailsList = [], setEmailsList = () => {}, move = {}, appointment = {}, editMode, type = 'move' } = props;

  React.useEffect(() => {
    if (move && move.config && move.config.emailsToNotify) setEmailsList(move.config.emailsToNotify);
  }, [move]);

  React.useEffect(() => {
    if (appointment && appointment.config && appointment.config.emailsToNotify) setEmailsList(appointment.config.emailsToNotify);
  }, [appointment]);

  React.useEffect(() => {
    if (emailsList.length > 0) {
      emailsList.forEach(email => {
        if (!isValidEmail(email)) {
          toast.warn('Please enter a valid email address');
          setEmailsList(emailsList.filter(e => e !== email));
        }
      });
    }
    if (emailsList.length > 6) {
      toast.warn('You can only add up to 5 additional emails');
      setEmailsList(emailsList.slice(0, 6));
    }
  }, [emailsList]);

  const handleRemoveEmail = index => {
    setEmailsList(prevEmails => {
      return prevEmails.filter((_, i) => i !== index);
    });
  };

  const isValidEmail = email => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  return (
    <>
      <div className={cls.paper}>
        <Grid container spacing={2} alignItems='stretch'>
          <Grid item md={6} xs={12}>
            {
              type === 'move' ? (
                <Typography className={cls.sub}>
                You can opt to receive two emails updating you about the status of this move: when the vehicle is picked
                up and when it is delivered. Up to five email addresses may be included.
              </Typography>
            ) : (
              <Typography className={cls.sub}>
                Add up to 5 email addresses to receive a copy of the appointment offer.
            </Typography>
            )}

          </Grid>

          {editMode ? (
            <Grid item md={6} xs={12}>
              <Autocomplete
                multiple
                freeSolo
                value={emailsList}
                onChange={(e, value) => setEmailsList(state => value)}
                options={[]}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} onDelete={() => handleRemoveEmail(index)} />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Type an email address then press enter'
                    placeholder={emailsList.length >= 5 ? '' : 'Type and press enter'}
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid item md={6} xs={12}>
              <div className={cls.notifications}>
                {emailsList.length > 0 ? (
                emailsList.map((email, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    <Chip style={{ marginRight: '8px' }} label={email} />
                  </div>
                ))
                ) : (
                  <Typography variant='body2'>
                    No emails specified
                  </Typography>
                )}

              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}
