import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import dayjs from 'dayjs';
import { useData } from '../../DataProvider';
import { getAllowedCustomers, getUserRole, getCustomerId } from '../utils/authHelper';

import { makeStyles, Container, Typography, Switch, FormControlLabel, Grid, Chip } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query, ApolloConsumer, withApollo } from 'react-apollo';
import * as Sentry from '@sentry/react';

import Loading from '../utils/Loading';
import AppointmentsFilter from './appointments/AppointmentsFilter';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';
import DefaultEmptyFallback from '../reusable/Fallbacks/DefaultEmptyFallback';

import { Link } from 'react-router-dom';
import { Button } from '@hopdrive/storybook';

import sdk from '@hopdrive/sdk';

const log = false;

const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
};
const getDefaultRange = () => {
  const localRange = localStorage.getItem(`appointment-index-range`);
  if (localRange) return localRange;
  else return `thisWeek`; // Changed default
};
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`appointment-index-range`);
  const localStart = localStorage.getItem(`appointment-index-start`);
  if (localRange === `custom` && localStart) return localStart;
  
  switch(localRange) {
    case 'today':
      return dayjs().startOf('day').format();
    case 'thisWeek':
      return dayjs().startOf('week').format();
    case 'nextWeek':
      return dayjs().add(1, 'week').startOf('week').format();
    case 'thisMonth':
      return dayjs().startOf('month').format();
    case 'thisYear':
      return dayjs().startOf('year').format();
    default:
      return dayjs().startOf('week').format();
  }
};
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`appointment-index-range`);
  const localEnd = localStorage.getItem(`appointment-index-end`);
  if (localRange === `custom` && localEnd) return localEnd;
  
  switch(localRange) {
    case 'today':
      return dayjs().endOf('day').format();
    case 'thisWeek':
      return dayjs().endOf('week').format();
    case 'nextWeek':
      return dayjs().add(1, 'week').endOf('week').format();
    case 'thisMonth':
      return dayjs().endOf('month').format();
    case 'thisYear':
      return dayjs().endOf('year').format();
    default:
      return dayjs().endOf('week').format();
  }
};

const defaultOrder = `desc`;
const defaultOrderBy = `APPOINTMENT_TIME`;

////////// COMPONENT //////////
function Appointments(props) {
  const ctx = useData();
  const cls = useStyles();

  const [hasAppointmentPermissions, setHasAppointmentPermissions] = useState(false);

  const [disablePickers, setDisablePickers] = useState(false);
  const [range, setRange] = useState(`week`);
  const [start, setStart] = useState(dayjs.utc(dayjs().startOf(`day`).subtract(1, `week`)).format());
  const [end, setEnd] = useState(dayjs.utc(dayjs().endOf(`day`)).format());

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const [allCustomersChecked, setAllCustomersChecked] = useState(false);
  const [customersArray, setCustomersArray] = useState([]);
  const [isAdmin, setIsAdmin] = useState(null);

  const getConfig = async customerId => {
    const parsedId = parseInt(customerId);
    const config = await sdk.configs.getCustomerConfig(parsedId);
    if (config && config.appointments && config.appointments.enabled && config.appointments.enabled === true) {
      setHasAppointmentPermissions(true);
    } else setHasAppointmentPermissions(false)
  };
  
  const handleRefetch = async () => {
    try {
      await ctx.apolloClient.query({
        query: GET_APPOINTMENTS,
        variables: {
          customerId: ctx.customerOverride || ctx.customerId,
          start: start,
          end: end,
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    let defaultDisable = getDefaultDisable();
    setDisablePickers(defaultDisable);
    let defaultRange = getDefaultRange();
    setRange(defaultRange);
    let defaultStart = getDefaultStart();
    setStart(defaultStart);
    let defaultEnd = getDefaultEnd();
    setEnd(defaultEnd);

    const buildAllowedCustomersArray = async () => {
      let customers = await getAllowedCustomers();
      let customer = await getCustomerId();
      customer = parseInt(customer);
      setCustomersArray(customers);
    };

    const checkIfAdmin = async () => {
      const role = await getUserRole();
      if (role && role.includes('admin')) {
        setIsAdmin(true);
      } else setIsAdmin(false);
    };

    buildAllowedCustomersArray();
    checkIfAdmin();
  }, []);

  React.useEffect(() => {
    handleRefetch();
    if (ctx.customerOverride || ctx.customerId) {
      getConfig(ctx.customerOverride || ctx.customerId);
    }
  }, [ctx.customerOverride, ctx.customerId, customersArray, ctx.apolloClient]);

  const goToAppointmentDetails = appointmentId => {
    props.history.push(`/appointments/${appointmentId}`);
  };

  // Control range picker
  const handleRangeChange = value => {
    localStorage.setItem(`appointment-index-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      
      switch(value) {
        case 'today':
          setStart(dayjs().startOf('day').format());
          setEnd(dayjs().endOf('day').format());
          break;
        case 'thisWeek':
          setStart(dayjs().startOf('week').format());
          setEnd(dayjs().endOf('week').format());
          break;
        case 'nextWeek':
          setStart(dayjs().add(1, 'week').startOf('week').format());
          setEnd(dayjs().add(1, 'week').endOf('week').format());
          break;
        case 'thisMonth':
          setStart(dayjs().startOf('month').format());
          setEnd(dayjs().endOf('month').format());
          break;
        case 'thisYear':
          setStart(dayjs().startOf('year').format());
          setEnd(dayjs().endOf('year').format());
          break;
      }
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = dayjs.utc(dayjs(value).startOf(`day`)).format();
      localStorage.setItem(`move-index-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = dayjs.utc(dayjs(value).endOf(`day`)).format();
      localStorage.setItem(`move-index-end`, newDate);
      setEnd(newDate);
    }
  };

  const applyFilters = data => {
    const filterBySearch = () => {
      if (!search || search.length < 1) {
        let filteredData = data.filter(appointment => appointment.id);
        return filteredData;
      } else {
        return data.filter(appointment => {
          console.log(appointment);
          if (
            (appointment.id && (appointment.id + ``).toLocaleLowerCase().includes(search)) ||
            (appointment.appointment_time && appointment.appointment_time.toLocaleLowerCase().includes(search)) ||
            (appointment.consumer_name && appointment.consumer_name.toLocaleLowerCase().includes(search)) ||
            (appointment.vehicle_make && appointment.vehicle_make.toLocaleLowerCase().includes(search)) ||
            (appointment.vehicle_year && appointment.vehicle_year.toString().includes(search)) ||
            (appointment.vehicle_model && appointment.vehicle_model.toLocaleLowerCase().includes(search)) ||
            (appointment.vehicle_vin && appointment.vehicle_vin.toLocaleLowerCase().includes(search)) ||
            (appointment.status && appointment.status.toLocaleLowerCase().includes(search)) ||
            (appointment.customer_id && appointment.customer_id.toString().includes(search)) ||
            (appointment.move_id && appointment.move_id.toString().includes(search)) ||
            (appointment.customer && appointment.customer.name && appointment.customer.name.toLocaleLowerCase().includes(search)) ||
            (appointment.config && appointment.config.claim_number && appointment.config.claim_number.toLocaleLowerCase().includes(search))
          ) {
            return true;
          } else return false;
        });
      }
    };

    const filteredBySearch = filterBySearch();
    return filteredBySearch;
  };

  const formatTime = time => {
    if (time && typeof time === `string`) {
      let newTime = dayjs(time).format(`MM/DD/YYYY hh:mm A z`);
      newTime = newTime.replace(/\s/g, '\xa0');
      return newTime;
    }
    return null;
  };

  const getRowActions = appointment => {
    return [
      { name: `appointment-details`, label: `Appointment\xa0Details`, data: { appointment: appointment }, handler: handleContextMenuClick },
    ];
  };

  const handleContextMenuClick = async (e, data, target) => {
    if (data.action.name === `appointment-details`) {
      goToAppointmentDetails(data.appointment.id);
      return;
    }
  };

  const handleSwitch = event => {
    setAllCustomersChecked(event.target.checked);
  };

  //Only show "rooftop" column in table if user is type admin/dealer-admin
  const handleTableHeaders = () => {
    const headers = [
      { id: 'ROOFTOP', alignLeft: true, numeric: true, label: 'Rooftop' },
      { id: 'STATUS', alignLeft: true, numeric: false, label: 'Status' },
      { id: 'APPOINTMENT_TIME', alignLeft: true, numeric: false, label: 'Appointment Time' },
      { id: 'CONSUMER_NAME', alignLeft: true, numeric: false, label: 'Consumer Name' },
      { id: 'VEHICLE', alignLeft: true, numeric: false, label: 'Vehicle' },
      { id: 'CLAIM_NUMBER', alignLeft: true, numeric: false, label: 'Claim Number' },
    ];

    return headers;
  };

  const handleTableRows = appointment => {
    
    const getChipColor = (status) => {
      const statusLower = status.toLowerCase();
      if (['ready', 'paying'].includes(statusLower)) return  `#486496`; // blue
      if (['failed'].includes(statusLower)) return `#f44232`; // red
      if (statusLower === 'paid') return '#4caf50'; // green
      return '#757575'; // default gray
    };

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    return {
      CUSTOMER: appointment.customer && appointment.customer.name ? appointment.customer.name : '-',
      STATUS: appointment.status ? (
        <Chip 
          label={capitalizeFirstLetter(appointment.status)}
          variant="outlined"
          size="small"
          style={{
            borderColor: getChipColor(appointment.status),
            color: getChipColor(appointment.status)
          }}
        />
      ) : '-',
      STATUS_RAW: appointment.status ? appointment.status.toLowerCase() : '-', 
      APPOINTMENT_TIME: formatTime(appointment.appointment_time),
      CONSUMER_NAME: appointment.consumer_name || '-',
      VEHICLE: getFormattedVehicleFromAppointment(appointment),
      CLAIM_NUMBER: appointment && appointment.config && appointment.config.claim_number ? appointment.config.claim_number : '-',
      appointment: appointment,
    };
  };

  const getFormattedVehicleFromAppointment = (appointment) => {
    const { vehicle_year, vehicle_make, vehicle_model } = appointment;
    if (vehicle_year && vehicle_make && vehicle_model) {
      return `${vehicle_year} ${vehicle_make} ${vehicle_model}`;
    }
    return 'N/A';
  };

  const handleTableColumns = row => {
    const columns = [
      { align: 'left', value: row.CUSTOMER },
      { align: 'left', value: row.STATUS, rawValue: row.STATUS_RAW },
      { align: 'left', value: row.APPOINTMENT_TIME },
      { align: 'left', value: row.CONSUMER_NAME },
      { align: 'left', value: row.VEHICLE },
      { align: 'left', value: row.CLAIM_NUMBER },
    ];

    return columns;
  };

  if (!hasAppointmentPermissions) {
    return <DefaultEmptyFallback message='RESTRICTED ACCESS' />;
  }

  return (
    <>

      <div className={cls.root}>
        <Container maxWidth='lg'>
          {/* Change to flexbox */}
          <div className={cls.header}>
            <Grid container spacing={2} alignItems="center">
              {/* TITLE */}
              <Grid item>
                <Typography className={cls.titleText}>Appointments</Typography>
              </Grid>

              {/* BUTTON */}
              <Grid item>
                <Link to='/appointments/add'>
                  <Button color='primary' data-testid='add-appointment-button' size='large'>
                    Add&nbsp;Appointment
                  </Button>
                </Link>
              </Grid>

              {/* SPACER */}
              <Grid item xs />

              {/* SWITCH */}
              <Grid item>
                {isAdmin ? (
                  <FormControlLabel
                    className={cls.allCustomerSwitch}
                    control={
                      <Switch
                        checked={allCustomersChecked}
                        onChange={handleSwitch}
                        inputProps={{ 'aria-label': 'all customers checkbox' }}
                      />
                    }
                    label='Show All Rooftops'
                  />
                ) : null}
              </Grid>
            </Grid>
          </div>

          <AppointmentsFilter
            refetch={handleRefetch}
            range={range}
            start={start}
            end={end}
            onRangeChange={handleRangeChange}
            onDateChange={handleDateChange}
            disablePickers={disablePickers}
          />

          {ctx && ctx.firebaseUser && (
            <ApolloConsumer>
              {client => (
                <Query
                  query={GET_APPOINTMENTS}
                  variables={{
                    customerId: ctx.customerOverride || ctx.customerId,
                    start: start,
                    end: end,
                  }}
                  onError={error => {
                    console.error(error);
                    Sentry.captureException(error);
                  }}
                  fetchPolicy='network-only'
                >
                  {({ loading, data }) => {
                    if (loading) {
                      return <Loading fixed />;
                    }
                    if (data && data.appointments && data.appointments.length > 0) {
                      const filteredData = applyFilters(data.appointments);
                      const headers = handleTableHeaders();
                      const rows = filteredData.map(appointment => handleTableRows(appointment));


                      return (
                        <>
                          <AccordianTable
                            title={`${rows.length} APPOINTMENTS`}
                            headers={headers}
                            rows={rows}
                            search={search}
                            defaultOrder={defaultOrder}
                            defaultOrderBy={defaultOrderBy}
                            order={order}
                            orderBy={orderBy}
                            tablePage={tablePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            setSearch={setSearch}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                            setTablePage={setTablePage}
                            setRowsPerPage={setRowsPerPage}
                            setExpandedRowId={setExpandedRowId}
                            className={cls.table}
                            refetch={handleRefetch}
                            refreshPersistAs={'appointments'}
                            type={'appointments'}
                          >
                            {TableSort.stableSort(rows, TableSort.getSorting(order,orderBy === 'STATUS' ? 'STATUS_RAW' : orderBy))
                              .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                              .map(row => (
                                <AccordianRow
                                  key={`index-appointment-${row.appointment.id}`}
                                  rowId={row.appointment.id}
                                  expandedRowId={expandedRowId}
                                  setExpandedRowId={setExpandedRowId}
                                  columns={handleTableColumns(row)}
                                  actions={getRowActions(row.appointment)}
                                  onClick={() => goToAppointmentDetails(row.appointment.id)}
                                  className={cls.row}
                                >
                                  <div></div>
                                </AccordianRow>
                              ))}
                          </AccordianTable>
                        </>
                      );
                    } else {
                      return (
                        <div className={cls.notFound}>
                          <Typography className={cls.notFoundTxt}>NO APPOINTMENTS FOUND</Typography>
                        </div>
                      );
                    }
                  }}
                </Query>
              )}
            </ApolloConsumer>
          )}
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  titleText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  allCustomerSwitch: {
    fontSize: '24px',
    fontWeight: 500,
  },
}));

////////// GRAPHQL //////////
const GET_APPOINTMENTS = gql`
  query get_appointments($customerId: Int!, $start: timestamptz!, $end: timestamptz!) {
    appointments(
      where: {
        customer_id: { _eq: $customerId },
        appointment_time: { _gte: $start, _lte: $end }
      }
      order_by: { appointment_time: desc }
    ) {
      id
      appointment_time
      consumer_name
      customer_id
      vehicle_make
      vehicle_year
      vehicle_model
      vehicle_vin
      move_id
      customer_id
      status
      customer {
        name
      }
      config
    }
  }
`;

////////// EXPORT //////////

const AppointmentsWithRouter = withRouter(Appointments);
const AppointmentsWithRouterAndApollo = withApollo(AppointmentsWithRouter);

export default AppointmentsWithRouterAndApollo;
